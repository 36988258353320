import React from "react";
import { Row, Col } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

const ProfileName = () => {
  const { user } = useAuth0();

  return (
    <>
      <Row className="align-items-center profile-header mb-4 text-center text-md-left">
        <Col md={2}>
          <img
            src={user.picture}
            alt="Profile"
            className="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
          />
        </Col>
        <Col md>
          <h2>{user.name}</h2>
          <p className="text-muted">{user.email}</p>
        </Col>
      </Row>
    </>
  )
};

export default withAuthenticationRequired(ProfileName);
