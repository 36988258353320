import React, { useState } from 'react';
import { Table, Button } from "reactstrap";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";
import { getConfig } from "../config";
import Loading from "../components/Loading";

import auth0 from 'auth0-js'; // for callAuthApiGetUserinfo
import { createAuth0Client } from '@auth0/auth0-spa-js'; // for callAnotherLoginWithPopup

export const CommandsComponent = () => {
  const { isAuthenticated, user, getAccessTokenSilently, getAccessTokenWithPopup, getIdTokenClaims, loginWithPopup, loginWithRedirect } = useAuth0();
  const [ accessToken, setAccessToken ] = useState(null);
  const [ idTokenClaims, setIdTokenClaims ] = useState(null);
  const [ apiResult, setApiResult] = useState(null);
  const { domain, domainMgmt, clientId, apiOrigin, audienceMgmtApi, audienceMfaApi, parameter_custom_key, parameter_custom_value } = getConfig();
  const AdminScope = {
    scope: "admin:pizza42"
  }
  const MgmtApiOption = {
    audience: audienceMgmtApi,
    scope: "read:current_user update:current_user_identities update:current_user_metadata create:current_user_metadata delete:current_user_metadata create:current_user_device_credentials delete:current_user_device_credentials"
  };

  const MfaApiOption = {
    audience: audienceMfaApi,
    scope: "enroll read:authenticators remove:authenticators"
  };

  const callGetAccessTokenSilently = async () => {
    try {
      console.log("call getAccessTokenSilently()");
      setAccessToken(await getAccessTokenSilently());
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenWithPopup = async () => {
    try {
      console.log("call getAccessTokenWithPopup()");
      setAccessToken(await getAccessTokenWithPopup());
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenSilentlyWithAdminScope = async () => {
    try {
      console.log(`call getAccessTokenSilently({authorizationParams: AdminScope})`);
      setAccessToken(await getAccessTokenSilently({authorizationParams: AdminScope}));
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenSilentlyWithMgmtApiAudience = async () => {
    try {
      console.log(`call getAccessTokenSilently({authorizationParams: MgmtApiOption})`);
      setAccessToken(await getAccessTokenSilently({authorizationParams: MgmtApiOption}));
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenSilentlyWithMfaApiAudience = async () => {
    try {
      console.log(`call getAccessTokenSilently({authorizationParams: MfaApiOption})`);
      setAccessToken(await getAccessTokenSilently({authorizationParams: MfaApiOption}));
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenWithPopupWithAdminScope = async () => {
    try {
      console.log(`call getAccessTokenWithPopup({authorizationParams: AdminScope})`);
      setAccessToken(await getAccessTokenWithPopup({authorizationParams: AdminScope}));
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenWithPopupWithMgmtApiAudience = async () => {
    try {
      console.log(`call getAccessTokenWithPopup({authorizationParams: MgmtApiOption})`);
      setAccessToken(await getAccessTokenWithPopup({authorizationParams: MgmtApiOption}));
    } catch (e) {
      console.error(e);
    }
  }

  const callGetAccessTokenWithPopupWithMfaApiAudience = async () => {
    try {
      console.log(`call getAccessTokenWithPopup({authorizationParams: MfaApiOption})`);
      setAccessToken(await getAccessTokenWithPopup({authorizationParams: MfaApiOption}));
    } catch (e) {
      console.error(e);
    }
  }

  const callGetIdTokenClaims = async () => {
    try {
      console.log("call getIdTokenClaims()");
      setIdTokenClaims(await getIdTokenClaims());
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithPopup = async () => {
    try {
      console.log("call loginWithPopup()");
      await loginWithPopup();
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithPopupWithAdminScope = async () => {
    try {
      console.log("call loginWithPopup({authorizationParams: AdminScope})");
      await loginWithPopup({authorizationParams: AdminScope});
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithRedirect = async () => {
    try {
      console.log("call loginWithRedirect()");
      await loginWithRedirect();
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithRedirectWithConnectionAzuread = async () => {
    try {
      console.log("call loginWithRedirect({authorizationParams: {connection: \"azuread\"}})");
      await loginWithRedirect({authorizationParams: {connection: "azuread"}});
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithRedirectWithMgmtAudience = async () => {
    try {
      console.log("call loginWithRedirect({authorizationParams: MgmtApiOption})");
      await loginWithRedirect({authorizationParams: MgmtApiOption});
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithRedirectWithAdminScope = async () => {
    try {
      console.log("call loginWithRedirect({authorizationParams: AdminScope})");
      await loginWithRedirect({authorizationParams: AdminScope});
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithRedirectPromptLogin = async () => {
    try {
      console.log("call loginWithRedirect({authorizationParams:{prompt: 'login', redirectUri: window.location.origin + '/test', login_hint: user.email}})");
      console.log("redirectUri:", window.location.origin + "/test");
      console.log("login_hint:", user.email);
      await loginWithRedirect({authorizationParams:{
        prompt: 'login',
        redirectUri: window.location.origin + "/test",
        login_hint: user.email
      }});
    } catch (e) {
      console.error(e);
    }
  }

  const callLoginWithRedirectWithParameter = async () => {
    try {
      console.log("call loginWithRedirect({authorizationParams:{custom_key: custom_value}})");
      console.log("parameter_custom_key:", parameter_custom_key);
      console.log("parameter_custom_value:", parameter_custom_value);
      await loginWithRedirect({authorizationParams:{
        [parameter_custom_key]: parameter_custom_value
      }});
    } catch (e) {
      console.error(e);
    }
  }

  const callAnotherLoginWithPopupGetIdTokenClaims = async () => {
    console.log("createAuth0Client & call loginWithPopup({authorizationParams:{max_age: 0, scope: 'openid'}})");
    const anotherAuth0 = await createAuth0Client({
      domain: domain,
      client_id: clientId
    });
    await anotherAuth0.loginWithPopup({authorizationParams:{
      max_age: 0,
      scope: "openid"
    }});
    setApiResult(JSON.stringify(await anotherAuth0.getIdTokenClaims()));
  }

  const callAuthApiGetUserinfo = async () => {
    if(accessToken) {
      console.log("call auth0.Authentication.userInfo()");

      const auth0authApi = new auth0.Authentication({  
        domain: domain,
        clientID: clientId
      });
      
      auth0authApi.userInfo(accessToken, (err, ret) => {
        console.log(ret);
        setApiResult(JSON.stringify(ret));
      })  
    } else {
      console.log("No Access Token");
      setApiResult("No Access Token");
    }
  }

  const callMgmtApiGetUser = async () => {
    if(accessToken) {
      console.log("call auth0.Management.getUser()");

      const auth0MgmtApi = new auth0.Management({  
        domain: domainMgmt,
        token: accessToken
      });
      
      auth0MgmtApi.getUser(user.sub, (err, ret) => {
        console.log(ret);
        setApiResult(JSON.stringify(ret));
      })  
    } else {
      console.log("No Access Token");
      setApiResult("No Access Token");
    }
  }

  const callMgmtApiPatchUserMetadata = async () => {
    if(accessToken) {
      console.log("call auth0.Management.patchUserMetadata()");

      const auth0MgmtApi = new auth0.Management({  
        domain: domainMgmt,
        token: accessToken
      });
      
      auth0MgmtApi.patchUserMetadata(user.sub, {message: "Added by SPA App"}, (err, ret) => {
        console.log(ret);
        setApiResult(JSON.stringify(ret));
      })  
    } else {
      console.log("No Access Token");
      setApiResult("No Access Token");
    }
  }

  const callPizza42ApiUserVerificationEmail = async () => {
    if(accessToken) {
      console.log("Pizza42: POST /user/verification-email");

      const response = await fetch(`${apiOrigin}/user/verification-email`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${accessToken}`,
        }
      });
      const responseData = await response.json();    
      console.log("responseData:", responseData);
      setApiResult(JSON.stringify(responseData));
    } else {
      console.log("No Access Token");
      setApiResult("No Access Token");
    }
  }

  const callTest00 = async () => {
    console.log("Test00");

    const randomStringFromServer = "1234567890123456";
    const publicKeyCredentialCreationOptions = {
        challenge: Uint8Array.from(
            randomStringFromServer, c => c.charCodeAt(0)),
        rp: {
            name: "Sandbox00",
            id: "makehistory.in.net",
        },
        user: {
            id: Uint8Array.from(
                "UZSL85T9AFC", c => c.charCodeAt(0)),
            name: "user001@atko.emil",
            displayName: "User001",
        },
        pubKeyCredParams: [
          {
            "type": "public-key",
            "alg": -7
          },],
        //   {
        //     "type": "public-key",
        //     "alg": -8
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -36
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -37
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -38
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -39
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -257
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -258
        //   },
        //   {
        //     "type": "public-key",
        //     "alg": -259
        //   }],
        authenticatorSelection: {
            authenticatorAttachment: "cross-platform",
        },
        timeout: 60000,
        attestation: "direct"
    };
    console.log("navigator.credentials.create()", publicKeyCredentialCreationOptions);
    const credentials = await navigator.credentials.create({publicKey: publicKeyCredentialCreationOptions});
    console.log(credentials);
    console.log(credentials.response.attestationObject);

    let binaryString = "";
    const bytes = new Uint8Array(credentials.response.attestationObject);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i++) {
      binaryString += bytes[i].toString(16) + " ";
//      binaryString += String.fromCharCode(bytes[i]);
    }
    console.log(binaryString);
  }
  const handle = (e, fn) => {
    e.preventDefault();
    fn();
  };

  return (
    <>
      <div>
        <h2 className="mt-4 mb-2">Call and update variables</h2>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenSilently)}>SDK: getAccessTokenSilently()</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenWithPopup)}>SDK: getAccessTokenWithPopup()</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetIdTokenClaims)}>getIdTokenClaims()</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenSilentlyWithAdminScope)}>SDK: getAccessTokenSilently({'{'}authorizationParams: AdminScope{'}'})</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenWithPopupWithAdminScope)}>SDK: getAccessTokenWithPopup({'{'}authorizationParams: AdminScope{'}'})</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenSilentlyWithMgmtApiAudience)}>SDK: getAccessTokenSilently({'{'}authorizationParams: MgmtApiOption{'}'})</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenWithPopupWithMgmtApiAudience)}>SDK: getAccessTokenWithPopup({'{'}authorizationParams: MgmtApiOption{'}'})</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenSilentlyWithMfaApiAudience)}>SDK: getAccessTokenSilently({'{'}authorizationParams: MfaApiOption{'}'})</Button>
        <Button className="m-2" color="secondary" onClick={(e) => handle(e, callGetAccessTokenWithPopupWithMfaApiAudience)}>SDK: getAccessTokenWithPopup({'{'}authorizationParams: MfaApiOption{'}'})</Button>
        <h2 className="mt-4 mb-2">Just call</h2>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithRedirect)}>SDK: loginWithRedirect()</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithRedirectWithConnectionAzuread)}>SDK: loginWithRedirect({'{'}authorizationParams: {'{'}connection: "azuread"{'}'}{'}'})</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithRedirectWithMgmtAudience)}>SDK: loginWithRedirect({'{'}authorizationParams: MgmtApiOption{'}'})</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithRedirectWithAdminScope)}>SDK: loginWithRedirect({'{'}authorizationParams: AdminScope{'}'})</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithRedirectPromptLogin)}>SDK: loginWithRedirect({'{'}authorizationParams:{'{'}prompt: 'login', redirectUri: window.location.origin + '/test', login_hint: user.email{'}'}{'}'})</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithRedirectWithParameter)}>SDK: loginWithRedirect({'{'}authorizationParams:{'{'}custom_key: custom_value{'}'}{'}'})</Button>
        <h2 className="mt-4 mb-2">Reflect to 'Result'</h2>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithPopup)}>SDK: loginWithPopup()</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callLoginWithPopupWithAdminScope)}>SDK: loginWithPopup({'{'}authorizationParams: AdminScope{'}'})</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callAnotherLoginWithPopupGetIdTokenClaims)}>SDK: createAuth0Client & loginWithPopup({'{'}authorizationParams:{'{'}max_age: 0, scope: 'openid'{'}'}{'}'})</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callAuthApiGetUserinfo)}>auth0.js: auth0.Authentication.userInfo()</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callMgmtApiGetUser)}>auth0.js: auth0.Management.getUser()</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callMgmtApiPatchUserMetadata)}>auth0.js: auth0.Management.patchUserMetadata()</Button>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callPizza42ApiUserVerificationEmail)}>Pizza42: POST /user/verification-email</Button>
        <h2 className="mt-4 mb-2">Others</h2>
        <Button className="m-2" color="primary" onClick={(e) => handle(e, callTest00)}>Test 00</Button>
      </div>
      <div>
        <h1 className="mt-4">Result</h1>
      </div>
      <div className="mt-4">
        <div style={{border: '1px solid #DDDDDD', minHeight: '1rem', padding: '1rem', wordBreak: "break-word"}}>{ apiResult }</div>
      </div>
      <div>
        <h1 className="mt-4">Variable</h1>
      </div>
      <div>
        <Table className="w-100">
          <tbody>
            <tr><td>isAuthenticated</td><td> { isAuthenticated ? "ture": "false" }</td></tr>
            <tr><td>user</td><td className="data-cell"> { JSON.stringify(user) }</td></tr>
            <tr><td>Access Token</td><td className="data-cell">{ accessToken }</td></tr>
            <tr><td>ID Token Claims</td><td className="data-cell">{ JSON.stringify(idTokenClaims) }</td></tr>
          </tbody>
        </Table>
      </div>
    </>
  );
};

export default withAuthenticationRequired(CommandsComponent, {
  onRedirecting: () => <Loading />,
});

