import React, { useState } from "react";
import { Row } from "reactstrap";
import jwt_decode from "jwt-decode";
import { useAuth0, withAuthenticationRequired } from "@auth0/auth0-react";

import { getConfig } from "../config";
import Highlight from "../components/Highlight";

const ProfileToken = () => {
  const { apiOrigin = "http://localhost:3001" } = getConfig();
  const { getAccessTokenSilently, getIdTokenClaims } = useAuth0();
  const [tokens, setTokens] = useState({
    accessToken: null,
    accessTokenRaw: null,
    idToken: null,
    idTokenRaw: null
  });

  const getAccessToken = async () => {
    try {
      console.log("call getAccessTokenSilently(audience: \"" + apiOrigin + "/\")");
      const accessToken = await getAccessTokenSilently({authorizationParams: {
        audience: apiOrigin + "/"
      }});
      console.log("accessToken:", accessToken);
      console.log(jwt_decode(accessToken));
      setTokens({
        ...tokens,
        accessToken: jwt_decode(accessToken),
        accessTokenRaw: accessToken
      })
    } catch (error) {
      console.error(error);
      return
    }
  }

  const getIdToken = async () => {
    try {
      console.log("call getIdTokenClaims()");
      const idToken = await getIdTokenClaims();
      console.log("idToken:", idToken.__raw);
      console.log(jwt_decode(idToken.__raw));
      setTokens({
        ...tokens,
        idToken: jwt_decode(idToken.__raw),
        idTokenRaw: idToken.__raw
      })
    }
    catch (error) {
      console.error(error);
      return
    }
  }

  if(!tokens.accessToken) {
    getAccessToken();
  }
  if(!tokens.idToken) {
    getIdToken();
  }

  return (
    <>
      <h3>Access Token (RAW)</h3>
      <Row>
        <Highlight>{tokens.accessTokenRaw}</Highlight>
      </Row>      
      <h3>Access Token</h3>
      <Row>
        <Highlight>{JSON.stringify(tokens.accessToken, null, 2)}</Highlight>
      </Row>      
      <h3>ID Token (RAW)</h3>
      <Row>
        <Highlight>{tokens.idTokenRaw}</Highlight>
      </Row>
      <h3>ID Token</h3>
      <Row>
        <Highlight>{JSON.stringify(tokens.idToken, null, 2)}</Highlight>
      </Row>
    </>
  )
};

export default withAuthenticationRequired(ProfileToken);