import React, { useState } from "react";
import { Container } from "reactstrap";
import { getConfig } from "../config";

const RopcLogin = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { domain, audienceMgmtApi } = getConfig();

  const handleChangeEmail = (event) => { setEmail(event.target.value); };
  const handleChangePassword = (event) => { setPassword(event.target.value);};

  const handleSubmit = async(event) => 
  {
    event.preventDefault();

    console.log("email: ", email);
    console.log("password:", password);
    console.log("-----")

    const result = await fetch(`https://${domain}/oauth/token`, {
      method: "POST",
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      body: new URLSearchParams({
        grant_type: 'password',
        username: email,
        password: password,
        audience: audienceMgmtApi,
        scope: 'openid profile email create:current_user_order read:current_user_orders',
        client_id: "qft4vnqxL9g6DdzZ06s55V1x7YdL2YnG"
      })
    });
    const result_json = await result.json();
    console.log(result_json);
    console.log("-----")
  }

  return (
    <Container className="mt-4">
      <form onSubmit={handleSubmit}>
        <label>email: <input type="text" value={email} onChange={handleChangeEmail} /></label><br />
        <label>Password: <input type="password" value={password} onChange={handleChangePassword} /></label><br />
        <input type="submit" value="Submit" />
      </form>
    </Container>
  );
}

export default RopcLogin;
